import router from '@/router'
import { getCookie } from '@/utils'

router.beforeEach(async (to, from, next) => {
  console.log('路由守卫 from', from.name)
  console.log('路由守卫 to', to.name)
  window.document.title = to.meta.title || '批办系统'
  // const management = ['userList'] // 路由白名单，不检验登陆
  const token = getCookie('token')

  if (from.name !== to.name && !token && to.name !== 'login') {
    next({ name: 'login' })
  } else if (from.name !== to.name && token && to.name === 'login') {
    next({ name: 'home' })
  } else {
    next()
  }
})
