export const dynamic = [
  {
    hidden: false,
    path: '/signFile',
    name: 'signFile',
    icon: 'Collection',
    component: () => import('@/views/Main/SignFile/SignFile.vue'),
    meta: {
      title: '签批文件'
    }
  },
  {
    hidden: false,
    path: '/filesLib',
    name: 'filesLib',
    icon: 'FolderOpened',
    component: () => import('@/views/Main/FilesLib/FilesLib.vue'),
    meta: {
      title: '文件库'
    }
  },
  {
    hidden: false,
    path: '/userList',
    name: 'userList',
    icon: 'User',
    component: () => import('@/views/Main/UserList/UserList.vue'),
    meta: {
      title: '签批领导录入'
    }
  },
  {
    hidden: false,
    path: '/department',
    name: 'department',
    icon: 'OfficeBuilding',
    component: () => import('@/views/Main/Department/Department.vue'),
    meta: {
      title: '科室管理'
    }
  },
  {
    hidden: false,
    path: '/management',
    name: 'management',
    icon: 'Management',
    component: () => import('@/views/Main/Management/Management.vue'),
    meta: {
      title: '权限管理'
    }
  }
]
