<template>
  <el-container class="layout-container-demo">
    <!-- 左边栏路由菜单 -->
    <el-aside width="160px">
      <Menu></Menu>
    </el-aside>

    <!-- 右边主体部分 -->
    <el-container>

      <!-- 头部导航 -->
      <el-header>
        <Header></Header>
      </el-header>

      <!-- 主体部分 -->
      <el-main>
        <Main></Main>
      </el-main>

    </el-container>
  </el-container>
  <Notice @notice="handleNotice"></Notice>
</template>

<script setup>
import Header from './Header/Header.vue'
import Main from './Main/Main.vue'
import Menu from './Menu/Menu.vue'
import Notice from '@/components/Notice.vue'

function handleNotice () {
  console.log(90)
}

</script>

<style scoped>
.layout-container-demo .el-header {
  height: 7vh;
  position: relative;
  background-color: var(--el-color-primary);
  color: var(--el-text-color-primary);
}
.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  /* background: var(--el-color-primary); */
}
.layout-container-demo .el-menu {
  border-right: none;
}
.layout-container-demo .el-main {
  padding: 0;
}
</style>
