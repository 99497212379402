import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/views/layout/Layout.vue'
import { hideRouter } from '@/router/modules/hideRouter'
import { dynamic } from '@/router/modules/dynamic'

export const routes = [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue')
  },
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: [
      ...hideRouter,
      ...dynamic
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
