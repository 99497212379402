import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getCookie, removeCookie, removeSession } from '@/utils'
import router from '@/router'
import { path } from '@/api/host'

const invalidCode = [40001, 40002, 40003, 40004]

const instance = axios.create({
  timeout: 120000,
  headers: {
    'Content-Type': 'application/json' // 'application/x-www-form-urlencoded'
  }
})

export const request = ({ url, method, data, headers }) => {
  method = method?.toLowerCase()
  const head = {
    Authorization: getCookie('token') || ''
  } // header 头公共配置参数
  const config = {
    url: path(url),
    method: method || 'get',
    headers: headers || {}
  }
  config.headers = Object.assign(head, config.headers)
  if (method === 'get') config.params = data
  if (method === 'post') config.data = data
  return new Promise((resolve, reject) => {
    instance(config).then(res => {
      if (res.data.code === 10000) {
        resolve(res.data.data)
      } else if (invalidCode.includes(parseInt(res.data.code))) {
        ElMessage({
          type: 'error',
          message: '登录超时，请重新登录'
        })
        removeCookie('token')
        removeCookie('realname')
        removeCookie('roles')
        removeSession('management')
        reject(res.data.msg || res.data.message || '网络错误')
        router.replace({ path: '/login' })
      } else {
        reject(res.data.msg || res.data.message || '网络错误')
      }
    }).catch((err, errType) => {
      reject(err, errType)
    })
  })
}
