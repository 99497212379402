export const getTimeState = () => {
  // 获取当前时间
  const timeNow = new Date()
  // 获取当前小时
  const hours = timeNow.getHours()
  // 设置默认文字
  let text = ''
  // 判断当前时间段
  if (hours >= 0 && hours <= 4) {
    text = '深夜好'
  } else if (hours > 4 && hours <= 9) {
    text = '早上好'
  } else if (hours > 9 && hours <= 12) {
    text = '上午好'
  } else if (hours > 12 && hours <= 14) {
    text = '中午好'
  } else if (hours > 14 && hours <= 18) {
    text = '下午好'
  } else if (hours > 18 && hours <= 24) {
    text = '晚上好'
  }
  // 返回当前时间段对应的状态
  return text
}

/**
 * @description 时间格式化
 * @param {Number} datetime 时间戳
 * @param {String} format 转化格式
 */
export const formatFixedDate = (datetime, format = 'yyyy-MM-dd HH:mm:ss') => {
  const date = new Date(datetime)
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hour = date.getHours()
  let minute = date.getMinutes()
  let second = date.getSeconds()
  month = month > 9 ? month : '0' + month
  day = day > 9 ? day : '0' + day
  hour = hour > 9 ? hour : '0' + hour
  minute = minute > 9 ? minute : '0' + minute
  second = second > 9 ? second : '0' + second
  switch (format) {
    case 'yyyy-MM-dd HH:mm:ss':
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    case 'yyyy-MM-dd':
      return `${year}-${month}-${day}`
  }
}

// 秒数转化为天时分秒
export const formatSeconds = (value) => {
  let second = parseInt(value) // 秒
  let minute = 0 // 分
  let hour = 0 // 时
  let day = 0 // 天
  // 如果秒数大于60，将秒数转换成整数
  if (second > 60) {
    // 获取分钟，除以60取整数，得到整数分钟
    minute = parseInt(second / 60)
    // 获取秒数，秒数取佘，得到整数秒数
    second = parseInt(second % 60)
    // 如果分钟大于60，将分钟转换成小时
    if (minute > 60) {
      // 获取小时，获取分钟除以60，得到整数小时
      hour = parseInt(minute / 60)
      // 获取小时后取佘的分，获取分钟除以60取佘的分
      minute = parseInt(minute % 60)
      // 如果小时大于24，将小时转换成天
      if (hour > 23) {
        // 获取天数，获取小时除以24，得到整天数
        day = parseInt(hour / 24)
        // 获取天数后取余的小时，获取小时除以24取余的小时
        hour = parseInt(hour % 24)
      }
    }
  }

  let result = '' + parseInt(second) + '秒'
  if (minute > 0) {
    result = '' + parseInt(minute) + '分' + result
  }
  if (hour > 0) {
    result = '' + parseInt(hour) + '时' + result
  }
  if (day > 0) {
    result = '' + parseInt(day) + '天' + result
  }
  return result
}
