export const hideRouter = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/Home.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/center',
    name: 'center',
    component: () => import('@/views/Center/Center.vue'),
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/signFileEdit',
    name: 'signFileEdit',
    component: () => import('@/views/Main/SignFile/components/Edit.vue'),
    meta: {
      title: '签批文件编辑'
    }
  }
]
