import { request } from '../api'

// 文件待处理列表
export const getFilePendingList = async (data, headers) => {
  return await request({
    url: '/api/cms/file/pending',
    method: 'get',
    data,
    headers
  })
}

// 文件处理中列表
export const getFileProcessingList = async (data, headers) => {
  return await request({
    url: '/api/cms/file/processing',
    method: 'get',
    data,
    headers
  })
}

// 文件处理中列表 - 预览专享
export const getFileInspectionProcessingList = async (data, headers) => {
  return await request({
    url: '/api/cms/file/inspection/processing',
    method: 'get',
    data,
    headers
  })
}

// 文件已撤回列表
export const getFileCancelingList = async (data, headers) => {
  return await request({
    url: '/api/cms/file/canceling',
    method: 'get',
    data,
    headers
  })
}

// 秘书文件处理中列表
export const getFileSecretaryList = async (data, headers) => {
  return await request({
    url: '/api/cms/file/secretary/processing',
    method: 'get',
    data,
    headers
  })
}

// 文件预览
export const fileView = async (data, headers) => {
  return await request({
    url: '/api/cms/file/view?',
    method: 'get',
    data,
    headers
  })
}

// 文件编辑
export const fileUpdate = async (data, headers) => {
  return await request({
    url: '/api/cms/file/update',
    method: 'post',
    data,
    headers
  })
}

// 文件编辑-其他科
export const fileOtherUpdate = async (data, headers) => {
  return await request({
    url: '/api/cms/file/otherUpdate',
    method: 'post',
    data,
    headers
  })
}

// 文件编辑5
export const fileTemplate5Update = async (data, headers) => {
  return await request({
    url: '/api/cms/file/template5Update',
    method: 'post',
    data,
    headers
  })
}

// 申请签批
export const fileApply = async (data, headers) => {
  return await request({
    url: '/api/cms/file/apply',
    method: 'post',
    data,
    headers
  })
}

// 文件归档
export const fileFiled = async (data, headers) => {
  return await request({
    url: '/api/cms/file/filed',
    method: 'post',
    data,
    headers
  })
}

// 进度查看
export const fileProcess = async (data, headers) => {
  return await request({
    url: '/api/cms/file/process',
    method: 'get',
    data,
    headers
  })
}

// 文件删除
export const fileDelete = async (data, headers) => {
  return await request({
    url: '/api/cms/file/delete',
    method: 'post',
    data,
    headers
  })
}

// 文件撤回
export const fileCancel = async (data, headers) => {
  return await request({
    url: '/api/cms/file/cancel',
    method: 'post',
    data,
    headers
  })
}

// 检测是否在转码接口
export const fileDetail = async (data, headers) => {
  return await request({
    url: '/api/cms/file/detail',
    method: 'get',
    data,
    headers
  })
}

// 加盖印章接口 { document_id: 1, x: 111, y: 222 }
export const fileAffixseal = async (data, headers) => {
  return await request({
    url: '/api/cms/file/affixseal',
    method: 'post',
    data,
    headers
  })
}

// 督查科文件处理中列表已读接口
export const inspectionRead = async (data, headers) => {
  return await request({
    url: '/api/cms/file/inspection/read',
    method: 'post',
    data,
    headers
  })
}

// 签字管理特殊搜索列表
export const getOperationList = async (data, headers) => {
  return await request({
    url: '/api/cms/file/operation/list',
    method: 'get',
    data,
    headers
  })
}
// 签字管理签字图片列表
export const getOperationSignList = async (data, headers) => {
  return await request({
    url: '/api/cms/file/operation/sign/list',
    method: 'get',
    data,
    headers
  })
}
// 签字管理签字图片删除
export const operationSignDelete = async (data, headers) => {
  return await request({
    url: '/api/cms/file/operation/sign/delete',
    method: 'post',
    data,
    headers
  })
}
// 文件管理重新上传
export const fileOperationUpload = async (data, headers) => {
  return await request({
    url: '/api/cms/file/operation/upload',
    method: 'post',
    data,
    headers
  })
}
// 签字管理签字图片删除
export const changeOperationStatus = async (data, headers) => {
  return await request({
    url: '/api/cms/file/operation/status',
    method: 'post',
    data,
    headers
  })
}
