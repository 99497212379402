import { fileDetail } from '@/api'
import { ElMessage } from 'element-plus'

/**
 * @description 检测是否转码
 * @param {Number} id 文件ID
 */
export function fileDetailApi (id) {
  return new Promise((resolve) => {
    fileDetail({ id }).then(res => {
      const transcodingStatus = res?.detail?.transcoding_status // 0 转码中  1 转码完成
      console.log('转码信息', transcodingStatus)
      if (transcodingStatus === 1) {
        resolve()
      } else {
        ElMessage({
          type: 'info',
          message: '文件转码中，请稍后操作'
        })
      }
    })
  })
}
