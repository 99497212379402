import { request } from '../api'

// 登录接口
export const login = async (data, headers) => {
  return await request({
    url: '/api/login',
    method: 'post',
    data,
    headers
  })
}

// 权限接口
export const permission = async (data, headers) => {
  return await request({
    url: '/api/admin/permission',
    method: 'get',
    data,
    headers
  })
}
