import { request } from '../api'

// 权限管理管理员列表
export const adminList = async (data, headers) => {
  return await request({
    url: '/api/admin/list',
    method: 'get',
    data,
    headers
  })
}

// 权限管理管理员创建
export const adminCreate = async (data, headers) => {
  return await request({
    url: '/api/admin/create',
    method: 'post',
    data,
    headers
  })
}

// 权限管理管理员编辑
export const adminUpdate = async (data, headers) => {
  return await request({
    url: '/api/admin/update',
    method: 'post',
    data,
    headers
  })
}
