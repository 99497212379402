const currentHost = window.location.host
let prefix = ''
if (currentHost.indexOf(':') > -1) {
  prefix = 'dev'
}

/**
 * 测试环境: http://m.yunshi.ren
 * 政务网: http://59.196.222.3
 * 移动物联网: http://172.20.205.26
 */
let baseApi = ''
switch (prefix) {
  case 'dev':
    baseApi = 'http://m.yunshi.ren'
    break
  default:
    baseApi = `http://${window.location.host}`
    break
}

const path = _path => baseApi + _path
console.log('baseApi', baseApi)
export {
  baseApi,
  path
}
