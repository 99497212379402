import { request } from '../api'

// 获取验证码接口
export const getBannerList = async (data, headers) => {
  return await request({
    url: '/mooc-server/frontend/home/getBannerList',
    method: 'post',
    data,
    headers
  })
}
