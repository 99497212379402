<template>
  <div class="toolbar">
    <el-row style="height: 100%">
      <el-col :span="4">
        <div class="toolbar_left"></div>
      </el-col>
      <el-col :span="16">
        <div class="toolbar_center"></div>
      </el-col>
      <el-col :span="4">
        <div class="toolbar_right">
          <el-dropdown @command="handleCommand">
            <div class="toolbar_user">
              <div class="user-avatar">
                <el-icon :size="30"><Avatar /></el-icon>
              </div>
              <span class="user-name">&nbsp; {{ realname }}</span>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="appDownload">文件签批助手下载</el-dropdown-item>
              </el-dropdown-menu>
              <el-dropdown-menu>
                <el-dropdown-item command="logOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { removeCookie, removeSession, getCookie } from '@/utils'
import { APP_DOWNLOAD_URL } from '@/constant'
import { useRouter } from 'vue-router'
const router = useRouter()

const realname = getCookie('realname')

function handleCommand (command) {
  if (command === 'appDownload') {
    window.open(APP_DOWNLOAD_URL)
  }
  if (command === 'logOut') {
    removeCookie('token')
    removeCookie('realname')
    removeCookie('roles')
    removeSession('management')
    removeSession('department_id')
    router.replace({ path: '/login' })
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  height: 100%;
  .toolbar_left {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .toolbar_center {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .toolbar_right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    .toolbar_user {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .user-avatar {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F2F2F2;
      }
      .user-name {
        color: #FFFFFF;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}
</style>
