<template>
  <div class="cms-title" @click="handleLinkHome">
    <el-icon :size="30" style="margin-right: 10px;"><Stamp /></el-icon>
    &nbsp; 二连浩特市 <br> 文件批办系统
  </div>
  <el-scrollbar class="scrollbar">
    <el-menu
      v-if="dynamicRouter"
      :default-active="store.state.currentRoute"
      :router="true"
      @select="selectMenu">

      <template
        v-for="(item, index) of dynamicRouter"
        :key="index">
        <el-menu-item
          v-if="state.management.includes(item.name)"
          :index="item.name"
          class="menu-item">
          <el-icon>
            <component :is="item.icon"/>
          </el-icon>
          <span>{{item.meta.title}}</span>
        </el-menu-item>
      </template>

    </el-menu>
  </el-scrollbar>
</template>

<script setup>
import { reactive, toRefs } from 'vue'
import { dynamic } from '@/router/modules/dynamic'
import { permission } from '@/api'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const router = useRouter()
const store = useStore()

function selectMenu (item) {
  store.commit('changeCurrentRoute', item)
}

function handleLinkHome () {
  router.replace({ path: '/' })
}

store.commit('changeCurrentRoute', router.currentRoute.value.name || '')
const state = reactive({
  management: [],
  dynamicRouter: null
})
const { dynamicRouter } = toRefs(state)

permission().then(res => {
  res.forEach(item => {
    state.management.push(item.action)
  })
  state.dynamicRouter = dynamic
})
</script>

<style lang="scss" scoped>
.cms-title {
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--el-color-primary);
  color: #FFFFFF;
  cursor: pointer;
}
.scrollbar {
  height: 93vh;
  background: #eff2f7;
  .menu-item {
    background: #eff2f7;
    &:hover {
      background: var(--el-color-primary-light-3);
      color: #FFFFFF;
    }
  }
}
</style>
