/**
 * @description 设置 sessionStorage
 * @param {String} name 需要存储 sessionStorage 的 key
 * @param {String} value 需要存储 sessionStorage 的 value
 * @intro 可以直接设置 json 而不是 json 串
 */
export const setSession = (key, value) => {
  const data = { value: value }
  sessionStorage[key] = JSON.stringify(data)
}

// 获取 sessionStorage
export const getSession = (key) => {
  var data = sessionStorage[key]
  if (!data || data === 'null') {
    return null
  }
  return JSON.parse(data).value
}

// 删除指定 sessionStorage
export const removeSession = (key) => {
  sessionStorage.removeItem(key)
}

// 清空所有 sessionStorage
export const clearSession = () => {
  sessionStorage.clear()
}
