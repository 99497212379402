<template>
  <el-scrollbar class="scrollbar_main">
    <div class="main">
      <router-view />
    </div>
  </el-scrollbar>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.scrollbar_main {
  height: 93vh;
  box-sizing: border-box;
  background: #FFFFFF;
  .main {
    height: 100%;
    margin: 10px 30px;
    background: #FFFFFF;
  }
}
</style>
