import { createStore } from 'vuex'

export default createStore({
  state: {
    currentRoute: '',
    signName: '1',
    noticeCount: 0
  },
  mutations: {
    changeCurrentRoute (state, currentRoute) {
      console.log('触发 vuex changeCurrentRoute', currentRoute)
      state.currentRoute = currentRoute
    },
    changeSignName (state, signName) {
      state.signName = signName
    },
    changeNoticeCount (state, noticeCount) {
      state.noticeCount = noticeCount
    }
  },
  actions: {
    changeCurrentRoute (store, currentRoute) {
      store.commit('changeCurrentRoute', currentRoute)
    },
    changeSignName (store, signName) {
      store.commit('changeSignName', signName)
    },
    changeNoticeCount (store, noticeCount) {
      store.commit('changeNoticeCount', noticeCount)
    }
  },
  modules: {
  }
})
