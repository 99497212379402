import { request } from '../api'

// 签批领导列表
export const leaderList = async (data, headers) => {
  return await request({
    url: '/api/cms/leader/list',
    method: 'get',
    data,
    headers
  })
}

// 签批领导创建
export const leaderCreate = async (data, headers) => {
  return await request({
    url: '/api/cms/leader/create',
    method: 'post',
    data,
    headers
  })
}

// 签批领导修改
export const leaderUpdate = async (data, headers) => {
  return await request({
    url: '/api/cms/leader/update',
    method: 'post',
    data,
    headers
  })
}
