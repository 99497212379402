<template>
  <div :class="{notice: true, shock: state.shock}" v-if="state.noticeShow">
    <el-popover placement="top-end" :width="500" trigger="click" :visible="state.popoverShow">
      <template #reference>
        <el-badge class="mark" :value="store.state.noticeCount" :hidden="!store.state.noticeCount">
          <el-button circle :icon="'BellFilled'" @click="handleClosePopover"></el-button>
        </el-badge>
      </template>
      <el-table :data="state.noticeList" stripe style="width: 100%" height="250">
        <el-table-column label="序号" align="center" type="index" width="60" />
        <el-table-column prop="fileName" label="文件名">
          <template #default="scope">
            <div v-if="scope.row.document">
              {{ scope.row.document.title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="进度" width="170">
          <template #default="scope">
            <p v-if="scope.row.leader">
              {{ scope.row.leader.name }}{{ scope.row.leader.job }}
              <span v-if="scope.row.type === 1">已<span style="color: #67c23a">完成</span>签批</span>
              <span v-if="scope.row.type === 2">已<span style="color: #f56c6c">退回</span>签批</span>
              <br>
              {{ scope.row.update_time }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="60">
          <template #default="scope">
            <el-button
              size="small"
              type="success"
              @click="handleFileView(scope.row, scope.$index)">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button
        v-if="state.noticeList?.length"
        class="confirmation"
        type="primary"
        @click="oneClickConfirmation">
        一键确认
      </el-button>
    </el-popover>
  </div>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { ElMessage } from 'element-plus'
import { queryMessageInfo, queryMessageRead, queryMessageReadAll } from '@/api'
import { formatFixedDate, setCookie, getCookie, removeCookie } from '@/utils'
import Music from '@/assets/audio/notice.mp3'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
const store = useStore()
const router = useRouter()

const state = reactive({
  noticeShow: true,
  popoverShow: true,
  noticeList: [],
  audio: null,
  markId: '',
  shock: false
})
let clock = ref(null)

function show () {
  state.noticeShow = true
}

// 提醒列表展示和隐藏
function handleClosePopover () {
  state.popoverShow = !state.popoverShow
  clewTone('muted')
}

// 点击查看
function handleFileView (item, index) {
  router.push({
    path: '/signFile'
  })
  store.commit('changeSignName', '2')
  store.commit('changeCurrentRoute', 'signFile')
  queryMessageReadApi(item.id, index)
}

function oneClickConfirmation () {
  queryMessageReadAll().then(res => {
    console.log('一键确认', res)
    store.state.noticeCount = 0
    handleClosePopover()
    ElMessage.success('操作成功')
    queryMessageInfoApi()
  }).catch(() => {
    ElMessage.error('操作失败')
  })
}

// 消息提醒提示音, muted: 是否静音
function clewTone (muted = '') {
  state.markId = state?.noticeList[0]?.id || ''
  state.audio = new Audio(Music)
  state.audio.muted = muted
  state.audio.addEventListener('canplaythrough', () => {
    state.audio.play()
  })
}

// 更新消息通知本地缓存的查询时间
function updateLocalNoticeDate () {
  if (!getCookie('noticeDateTime')) {
    setCookie('noticeDateTime', formatFixedDate(new Date().getTime()))
  }
}
updateLocalNoticeDate()

// 获取管理员消息列表接口
function queryMessageInfoApi () {
  const params = {
    last_read_time: getCookie('noticeDateTime')
  }
  queryMessageInfo(params).then(res => {
    if (res.list?.length) {
      state.popoverShow = true
      store.commit('changeNoticeCount', res.count)
      state.noticeList = res.list
      if (state.markId !== res.list[0].id) {
        clewTone()
        state.shock = true
      }
    } else {
      removeCookie('noticeDateTime')
      updateLocalNoticeDate()
    }
  }).catch(() => {
    clearInterval(clock)
  })
}
queryMessageInfoApi()

// 轮询查询消息列表接口
function polling () {
  clock = setInterval(() => {
    queryMessageInfoApi()
    state.shock = false
  }, 3000)
}
polling()

// 管理员消息-已阅读接口
function queryMessageReadApi (id, index) {
  // if (id) { return }
  queryMessageRead({ id }).then(res => {
    console.log('已读结果', res)
    state.noticeList.splice(index, 1)
    store.commit('changeNoticeCount', store.state.noticeCount > 0 ? store.state.noticeCount - 1 : 0)
    // 如果删的是第一个，重置
    if (index === 0) {
      state.markId = state.noticeList[0].id
    }
  })
}

defineExpose({
  show
})
</script>

<style lang="scss" scoped>
.notice {
  position: fixed;
  box-sizing: border-box;
  bottom: 10px;
  right: 20px;
  z-index: 9;
  &.shock {
    animation-delay: 0s;
    animation-name: shock;
    animation-duration: .1s;
    animation-iteration-count: 3;
    animation-direction: normal;
    animation-timing-function: linear;
  }
}
@keyframes shock {
  0% {
    margin-left: 0px;
    margin-right: 5px;
    margin-top: 0px;
  }
  100% {
    margin-left: 5px;
    margin-right: 0px;
    margin-top: 5px;
  }
}
.confirmation {
  float: right;
  margin-top: 10px;
}
</style>
