import Cookies from 'js-cookie'

export const getCookie = (key) => {
  return Cookies.get(key)
}

export const setCookie = (key, value, config) => {
  config ? Cookies.set(key, value, config) : Cookies.set(key, value)
}

export const removeCookie = (key) => {
  return Cookies.remove(key)
}
