import { request } from '../api'

// 科室管理列表
export const getDepartmentList = async (data, headers) => {
  return await request({
    url: '/api/cms/department/list',
    method: 'get',
    data,
    headers
  })
}

// 科室管理创建
export const departmentCreate = async (data, headers) => {
  return await request({
    url: '/api/cms/department/create',
    method: 'post',
    data,
    headers
  })
}

// 科室管理修改
export const departmentUpdate = async (data, headers) => {
  return await request({
    url: '/api/cms/department/update',
    method: 'post',
    data,
    headers
  })
}
