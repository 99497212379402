import { request } from '../api'

// 文件库(已归档)列表 年份查询 page=1&type=1&year=2022
// 文件库(已归档)列表 常规日期查询 page=1&type=2&title=订单&start_date=2022-01-01&end_date=2022-08-01
export const queryFileFinishList = async (data, headers) => {
  return await request({
    url: '/api/cms/file/finish',
    method: 'get',
    data,
    headers
  })
}

// 秘书文件库(已归档)列表 年份查询 page=1&type=1&year=2022
// 秘书文件库(已归档)列表 常规日期查询 page=1&type=2&title=订单&start_date=2022-01-01&end_date=2022-08-01
export const queryFileSecretaryList = async (data, headers) => {
  return await request({
    url: '/api/cms/file/secretary/finish',
    method: 'get',
    data,
    headers
  })
}

// 管理员消息
export const queryMessageInfo = async (data, headers) => {
  return await request({
    url: '/api/cms/message/info',
    method: 'post',
    data,
    headers
  })
}

// 管理员消息-已阅读
export const queryMessageRead = async (data, headers) => {
  return await request({
    url: '/api/cms/message/read',
    method: 'post',
    data,
    headers
  })
}

// 管理员消息-已阅读
export const queryMessageReadAll = async (data, headers) => {
  return await request({
    url: '/api/cms/message/readAll',
    method: 'post',
    data,
    headers
  })
}
